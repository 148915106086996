import PropTypes from 'prop-types'
import React from 'react'

import reporter from '@fundrocket/common/libs/reporter/adapter/web'
import Module1 from '@fundrocket/common-web/components/Module1'

import paths from 'constants/paths'

export default class ErrorBoundary extends React.Component {
  state = {}

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(error) {
    return { error: true }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error })

    reporter.setErrorContext(errorInfo)
    reporter.trackError(error, null)
  }

  render() {
    const { children, fallback, silent, to } = this.props
    const { error } = this.state

    if (error) {
      return silent
        ? null
        : fallback || (
            <Module1
              title="Darn, something went wrong"
              button="Go to home"
              buttonTo={to}
              className="ErrorBoundary">
              Sorry for the inconvenience.
            </Module1>
          )
    }

    return children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any,
  fallback: PropTypes.node,
  silent: PropTypes.bool,
  to: PropTypes.string,
}

ErrorBoundary.defaultProps = {
  to: paths.HOME,
}
