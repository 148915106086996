module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://bills.womply.com"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://235ccc2330f848abb16027dc2359c466@o132238.ingest.sentry.io/5304153","enabled":true,"environment":"stg","release":"fundrocket-web@abb47784d3acbe2c3e65578eb21e453d18d51800","normalizeDepth":6},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
