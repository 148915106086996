/* eslint-disable import/prefer-default-export, react/prop-types */
import React from 'react'

import ErrorBoundary from '@fundrocket/common-web/components/ErrorBoundary'
import { UserProvider } from 'contexts/User'
// import { WomplyUserProvider } from 'contexts/WomplyUserContext'

const onInitialClientRender = () => {
  setTimeout(function() {
    document.getElementById("___loader").style.display = "none"
  }, 300)
}

const wrapRootElement = ({ element }) => (
  <ErrorBoundary>
    <UserProvider>
      {element}
    </UserProvider>
  </ErrorBoundary>
)

export {
  onInitialClientRender,
  wrapRootElement,
}