const APP = '/app/'

export default {
  HOME: '/',
  FORBIDDEN: '/forbidden/',
  SIGN_IN: '/sign-in/',
  ERROR_NOT_FOUND: '/404/',
  ERROR_SERVER: '/500/',

  APP,
  PAY: `${APP}pay/`,
  PAYMENT: `${APP}payment/`,
  PAYMENTS: `${APP}payments/`,
  PAYMENT_METHOD_ADD: `${APP}payment-method/add/`,
  PAYMENT_METHOD: `${APP}payment-method/`,
  PAYMENT_METHODS: `${APP}payment-methods/`,
  RECIPIENT: `${APP}recipient/`,
  RECIPIENT_ADD: `${APP}recipient/add/`,
  RECIPIENTS: `${APP}recipients/`,
  SETTINGS: `${APP}settings/`,
  SUPPORT: `${APP}support/`,
}
