import PropTypes from 'prop-types'
import React from 'react'

const UserContext = React.createContext()

const { Consumer: User, Provider } = UserContext

class UserProvider extends React.Component {
  static propTypes = {
    children: PropTypes.any.isRequired,
  }

  state = {}

  updateUser = (data) => {
    this.setState({ ...data })
  }

  render() {
    const { children } = this.props
    const {
      businessName,
      contractOfferAmount,
      contractPurchasedPrice,
      firstName,
      lastName,
      offerAmount,
      offerId,
      offerSelected,
      percentage,
      phoneLast4,
      purchasedPrice,
      renewal,
      user,
    } = this.state

    return (
      <Provider
        value={{
          businessName,
          contractOfferAmount,
          contractPurchasedPrice,
          firstName,
          lastName,
          offerAmount,
          offerId,
          offerSelected,
          percentage,
          phoneLast4,
          purchasedPrice,
          renewal,
          user,
          updateUser: this.updateUser,
        }}>
        {children}
      </Provider>
    )
  }
}

export default User
export { UserContext, UserProvider }
